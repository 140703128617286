import { useState } from 'react';
import { hashString } from 'react-hash-string';
import * as _ from 'lodash';

const id = { username: 'Fiskagroup', password: '2027988036' }

export const Auth = {
    loginUser: function (credentials, alreadyHashed = false) {
        const password = alreadyHashed ? credentials.password : String(hashString(credentials.password));

        if (credentials.username !== id.username) {
            return "Nom d'utilisateur incorrect";

        } else if (password !== id.password) {
            return "Mot de passe incorrect";

        } else {
            return { token: id.username + '-' + id.password };
        };
    },

    setToken: function (userToken) {
        localStorage.setItem('token', JSON.stringify(userToken));
    },

    useToken: function () {
        const getToken = () => {
            const tokenString = localStorage.getItem('token');
            const userToken = JSON.parse(tokenString);

            if (_.get(userToken, 'token', '')) {
                if (userToken.token.includes('-')) {
                    const username = userToken.token.split('-')[0];
                    const password = userToken.token.split('-')[1];

                    // Vérifier si le mot de passe correspond et vérifié si ça renvoie bien le token
                    return _.isObject(this.loginUser({ username, password }, true));
                }
            }

            return userToken?.token
        };

        const [token, setToken] = useState(getToken());

        const saveToken = userToken => {
            localStorage.setItem('token', JSON.stringify(userToken));
            setToken(userToken.token);
        };

        return {
            setToken: saveToken,
            token
        }
    }
};

export default Auth;